<template>
  <component :is="patientModel === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="patientModel === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs v-if="patientModel" pills>
    

      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> {{$t('socialhistory')}}</span>
        </template>
        <medical-history :patientModel="patientModel" class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
       <!-- Action Buttons -->
       <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="Submit()"
    >
      {{$t('SaveChanges')}}
    </b-button>
    <b-button
    type="border" color="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="$router.go(-1)"
    >
      {{$t('back')}}
    </b-button>
  </component>
</template>


<script>
// import point from "../../components/point.vue";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleInsuranceCompany from "@/store/settings/insuranceCompany/moduleInsuranceCompany.js";
import medicalHistory from "@/views/patientProfile/medicalHistory.vue";
import { BAlert, BButton, BCard, BLink, BTab, BTabs } from "bootstrap-vue";

export default {
  components: {
    // point
    BTab,
    BButton,
    BTabs,
    BCard,
    BAlert,
    BLink,
    medicalHistory,
  },
  data() {
    return {
      EnglishLan:false,
      patientModel: {
        PatientMedicalHistory: {
          PatientMedicalHistoryMedications:[],
          PatientMedicalHistoryPastSurgeries:[]
        },
      },
      options: [],
      radios1: "luis",
    };
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    Submit() {
      this.$vs.loading();
      this.$store
        .dispatch("patientList/UpdatePatient", this.patientModel)
        .then((res) => {
          window.showSuccess(res.data.message);
          console.error(res);
          this.$vs.loading.close();
          this.$router.push({ name: "LandingPage" });

          // this.initPatientModelValues();
        })
        .catch((err) => {
          window.showError(err.response.data.message);
          this.$vs.loading.close();
          console.error(err);
        });
    },
  },
  computed: {},
  created() {
    debugger

      this.EnglishLan = localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null? true : false;

    debugger;
  if (!moduleInsuranceCompany.isRegistered) {
      this.$store.registerModule("InsuranceCompanyList", moduleInsuranceCompany);
      moduleInsuranceCompany.isRegistered = true;
    }

    this.$store.dispatch("InsuranceCompanyList/GetAllInsuranceCompanies");
    if (!modulePatient.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      debugger;
      this.$vs.loading();
      this.$store.dispatch("patientList/GetPatientForMainData", ID).then((res) => {
        this.patientModel = res.data.Data;
        if (
          this.patientModel.PatientMedicalHistory == null ||
          this.patientModel.PatientMedicalHistory == undefined
        ) {
          this.patientModel.PatientMedicalHistory = {};
        }
        else{
          debugger
        if(this.patientModel.PatientMedicalHistory.IsLowPressure==true){
            this.patientModel.PatientMedicalHistory.IsHavePresssure = undefined;
          }
          if(this.patientModel.NumOfChildren>0){
               this.patientModel.HaveChildren =  true;
             }
        }
        this.$vs.loading.close();

      });
    }
  },
};
</script>
<style>

.textfont {
  font-family: "futuraMedium";
}
</style>
